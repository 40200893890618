@import '../../css/variables.css';

.btn-section {
    padding-top: 90px;
    padding-bottom: 40px;
}

.btnText {
    border: none;
    border-radius: 30px;
    padding: 20px 40px;
    color: var(--primaryText);
    background: -webkit-linear-gradient(180deg, var(--primaryColor), var(--secondaryColor));
    font-size: 24px;
    font-weight: bold;

    cursor: pointer;
    transition: 0.2s;

}

.btnText:hover {
    transform: scale(1.1);
}

@media (min-width: 0px) and (max-width: 450px) {
    .btnText {
        padding: 20px 20px; 
        font-size: 1.7rem; 
    }
}

@media (min-width: 770px) and (max-width: 1024px) {
    .btn-section {
        padding-top: 0;
        padding-bottom: 30px; 
    }

    .btnText {
        padding: 40px 70px; 
        font-size: 2.3rem; 
    }
}