@import '../../css/variables.css';

.flex{
    display: flex;
}

footer {
    padding: 0 4%;  
    margin-bottom: 0;
    background-color: var(--primaryColor);
}


footer .flex{
    display: flex;
    justify-content: space-between; 
}

footer .line-footer{
    padding: 20px 0;
    
}

.borda{
    border-top: 2px solid #fff;
}

footer .social-icons button{
    background-color: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: none;
    font-size: 25px;
    cursor: pointer;
    margin: 0 5px;
    transition: .2s;

    justify-content: center;
}

footer .social-icons button:hover {
    transform: scale(1.3);
    margin-left: 5px;
}

footer .social-icons{
    justify-content: center;
}

footer .line-footer a {
    color: #fff;
    text-decoration: none;
    font-size: 29px;
    margin: 3px;

}

footer .logo-footer a{
    color: #fff;
    font-size: 40px;
}


.logo {
    position: absolute;
    background-color: var(--lightColor);
    border: 0;
    padding: 10px;
    border-radius: 50%;
    top: 30% !important;
    left: 15%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .imgLogo {
        border-radius: 50%;
        height: 10vw;
        width: 10vw;
        max-height: 80px;
        max-width: 80px;
        z-index: 2;
    }
}

@media  screen and (max-width: 1020px){
    .flex{
        flex-direction: column;
    }
}