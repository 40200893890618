@import '../../css/variables.css';

.benefit-data {
    border-bottom: 10px solid var(--primaryColor);
    padding: 0 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 200px;
    
    text-align: center;
    align-items: center;



    h2 {
        font-size: 45px;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        color: var(--darkColor); 
        margin: 0 auto;
    }

    h3 {
        font-size: 50px;
        font-family: "Romantic", sans-serif;
        font-weight: bold;
        color: var(--darkColor);
        margin: 0 auto;
    }

}

.description {
    padding: 0 4%;

    p {
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        padding: 0 4%;

        b {
            background: -webkit-linear-gradient(180deg, var(--primaryColor), var(--secondaryColor));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-style: normal;
            background-clip: text;
        }
    }
}

@media (min-width: 0px) and (max-width: 450px) {
    .benefit-data {

        .description p{
            font-size: 15px;
        }

    }
}



@media (min-width: 600px) and (max-width: 1024px){

    .benefit-data {
        height: 250px;

        h2 {
            font-size: 4rem;
        }
    }

    .description{
        
        p{
            font-size: 4rem;
        }
    }

}

