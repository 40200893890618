@import '../../css/variables.css';

.btnSession {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    padding-top: 12px;
}

.btnOrcamento {
    height: 44px;
    width: 165px;
    color: var(--secondaryColor);
    background-color: #FFFFFF;

    text-align: center;
    align-items: center;
    font-size: 18px;
    border: 0;
    border-radius: 26px;

    cursor: pointer;
    transition: 0.2s;
}

.btnOrcamento:hover{
    transform: scale(1.1);
}

@media (min-width: 0px) and (max-width: 450px){

    .btnOrcamento{
        border: 2px solid var(--secondaryColor);
        padding-bottom: 15px;
        font-size: 25px;
        padding-top: 10px;
        height: 60px;
        width: 200px;
    }
}
