@import '../../css/variables.css';

.range{
    background-color: var(--thirdColor);
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    p{
        color: var(--lightColor);
    }
}