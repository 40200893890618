@import '../css/variables.css';

.topo-site {
    position: relative;
    background-image: url(../../public/img/capturaGuincho.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.topo-site::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.text-topo {
    text-align: center;
    padding: 15px 4% 0 4%;
    color: var(--lightColor);
    z-index: 2;

    h1 {
        font-size: 4rem;
        font-family: var(--primaryFont);
        font-weight: bold;
    }

    p {
        margin-top: -30px;
        margin-bottom: 25px;
        font-size: 1.25rem;
        font-weight: bolder;
    }

    Button {
        padding: 17px 4%;
        border: 2px solid var(--lightColor);
        border-radius: 25px;
        font-family: var(--primaryFont);
        font-size: 1rem;
        cursor: pointer;
        transition: transform 0.2s, background-color 0.2s, color 0.2s;
        background-color: transparent;
        color: var(--lightColor);
    }

    Button:hover {
        transform: scale(1.1);
        color: var(--lightColor);
    }

    Button:focus,
    Button:active {
        background-color: transparent;
        color: var(--lightColor);
        outline: none;
    }


}

.title {
    text-align: center;
    margin-top: -15px;

    h1 {
        background-color: var(--lightColor);
        position: relative;
        margin: auto;
        margin-top: 25px;
        padding-bottom: 10px;
        z-index: 2;
        max-width: 400px;
        width: 100%;
    }

    hr {
        margin-top: -2px;
        z-index: 0;
    }

    span {
        color: var(--secondaryColor);
    }
}

.sessionCard {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 90px;
    margin-top: 40px;

    .cardGroup {
        margin: 0 auto;
        flex: 1;

        .custom-card {
            border: 1px solid #707070;
            box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.6);
            border-radius: 15px;
            margin: 10px;
            flex: 1;
            min-width: 300px;
            min-height: 312px;
            max-width: 300px;
            max-height: 312px;



            .card-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding-top: 40px;
            }

            .card-title {
                font-size: 1.25rem;
                font-weight: bold;
                margin: 20px 0;
                color: var(--darkColor);
            }

            .card-text {
                margin-top: 5px;
                font-size: 1rem;
                text-align: center;
                color: var(--darkColor);
            }
        }
    }
}

.benefit-section {
    padding: 60px 9%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 70px;
}

.cta-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-section {
    max-height: 600px;
    background-color: var(--thirdColor);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20%;
    padding-bottom: 30px;

    .robo {

        display: flex;
        justify-content: center;
        align-items: center;

        p{
            text-align: center;
        }

        .roboImg {
            max-height: 368px;
            max-width: 368px;
        }
    }
}

.sectionCardComments {
    padding: 0 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 0px) and (max-width: 450px) {

    .logo {
        display: none !important;
    }

    .imgLogo{
        display: none;
    }

    .text-topo {
        padding: 0 4%;
        margin-top: -60px;

        p {
            margin-top: -40px;
            font-size: 1.25rem;
            font-weight: bolder;
        }
    }

    .title {
        padding: 0 4%;

        h1 {
            padding-bottom: 10px;
            font-size: 1.7rem;
        }

        hr {
            margin-top: 1px;
        }
    }

    .sessionCard {
        gap: 50px;
        margin-bottom: 40px;
    }

    .benefit-section {
        padding: 0 4%;
        gap: 30px;
    }

    .form-section {
        height: 2000px;
        text-align: center;
        align-items: center;
        padding-bottom: 102px;
        padding-top: 70px;
    }

}


@media (min-width: 600px) and (max-width: 834px) {

    .logo {
        top: 22% !important;
    }

    .sessionCard {
        margin-bottom: 0;
    }

    .form-section{
        height: 1300px;
    }
}

@media (width: 1280px) {
    .logo {
        top: 38% !important;
    }
}

@media (min-width: 1281px) and (max-width: 1275px) {

    .logo {
        top: 55% !important;
    }
}

@media screen and (max-width: 1080px) {

    .sessionCard {
        gap: 90px;
        margin-top: 40px;

        .cardGroup {


            .custom-card {
                margin: 0;
            }
        }
    }


    .logo {
        top: 23%;
    }

    .benefit-section {
        flex-direction: column;
    }

    .benefit-data h2 {
        padding: 0 4%;
        text-align: center;
    }

    .form-section {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        height: 700px;
    }

    .robo {

        .roboImg {
            height: 200px;
            width: 200px;
        }
    }

    .sectionCardComments {
        flex-direction: column;
    }
}