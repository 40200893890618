.changeCommentButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryColor);
  }
  
  /* @media (width <= 768px) {
    .changeCommentButton {
      padding: 20px 10px;
    }
  }
   */