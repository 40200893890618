
:root{
    /* Cores */
    --primaryColor: #298FC2;
    --secondaryColor: #27acc1;
    --thirdColor: #2C3E50;
    --fourthColor: #2c3e50be;

    --primaryText :#0F172A;

    --darkColor: #000000;
    --lightColor: #ffffff;


    /* Font */

    --primaryFont: Arial, Helvetica, sans-serif;
}