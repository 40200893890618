.cardComponent {
  background-color: var(--thirdColor);
  color: #ffffff;
  width: 290px;
  max-width: 306px;
  height: 400px;
  padding-top: 40px;
  padding-bottom: 20px;
  border: 0;
  border-radius: 47px;
  text-align: center;
  position: relative;
  box-shadow: 35px 25px rgba(44, 62, 80, 0.5);
}

.logoCard {
  border-radius: 50%;
  border: solid 5px #ffffff7a;
}

@media (min-width: 0px) and (max-width: 450px) {
  .cardComponent {
    width: 250px;
    margin-bottom: 20px;
  }

  .card-title,
  .card-text {
    font-size: 20px;
  }
}

@media (min-width: 770px) and (max-width: 1024px) {
  .cardComponent {
    width: 600px;
    padding-top: 90px;
    padding-bottom: 0px;

    .card-title {
      font-size: 39px;
    }

    .card-text {
      font-size: 25px;
    }
  }
}