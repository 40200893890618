@import "../../../css/variables.css";

.formGroup {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
        padding: 0 2%;
    }
}

.inputForm {
    text-align: center;
}

.form {
    color: var(--lightColor);
    max-width: 600px;
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;


    h1 {
        text-align: center;
        margin: 40px;
    }

    p {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .formOrcamento {
        align-items: center;
        border-radius: 10px;
        margin-top: 5px;
        padding: 20px 4%;

    }

    .formControlOrcamento {
        padding: 10px 4%;
        background-color: var(--fourthColor);
        border-radius: 30px;
        height: 59px;
        width: 100%;
        max-width: 371px;
        margin-left: auto;
        margin-right: auto;
        color: white;
        font-size: 22.5px;
        margin-top: 10px;

    }
}

@media (min-width: 0px) and (max-width: 450px) {

    .formOrcamento {
        .formControlOrcamento {
            max-width: 100%;
            font-size: 18px;
        }
    }

    .inputForm {
        margin-left: 10px;
        width: 85%;
    }
}

@media screen and (max-width: 1080px) {
    .formGroup {
        margin: 0 auto;
        flex-direction: column;
        align-items: center;
    }

    .form {
        h1 {
            margin: 0;
            margin-top: -150px;
        }

        p {
            padding: 20px 2%;
        }
    }

    .formOrcamento {
        .formControlOrcamento {
            max-width: 300px;
            font-size: 20px;
        }
    }
}